<template>
  <b-form-group
    :label="label"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="forecastType"
      v-model="forecastType"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="forecastTypes"
      placeholder="Tất cả"
      @input="valueChanged"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forecastType: this.defaultValue,
      forecastTypes: [
        {
          name: 'Tất cả', value: null,
        },
        {
          name: 'Phân bổ đã nhận', value: false,
        },
        {
          name: 'Phân bổ dự đoán', value: true,
        },
      ],
    };
  },
  watch: {
    defaultValue(val) {
      this.forecastType = val;
    },
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.forecastType);
    },

  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
