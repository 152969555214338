import {
  ref, watch, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import { isEnableBed } from '@/auth/utils';

export default function useFinanceReportByMonth() {
  // Use toast
  const toastification = toast();

  const blankItem = {
    id: 0,
    apartment: null,
    floor: null,
    name: '',
    price: null,
    deposit: null,
    size: null,
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const rows = ref([]);

  // Table Handlers
  const columns = [

    {
      label: 'Tháng',
      field: 'month',
    },
    {
      label: 'Mô tả',
      field: 'description',
    },
    {
      label: 'Tòa nhà',
      field: 'apartment.name',
    },
    {
      label: 'Tên phòng',
      field: 'room.name',
    },
    {
      label: 'Tên giường',
      field: 'bed.name',
      hidden: rows.value.filter(row => row.bed && row.bed.id > 0).length === 0,
    },
    {
      label: 'Số tiền phân bổ',
      field: 'amount',
      type: 'number',
      width: '140px',
    },
    {
      label: 'Phân loại',
      field: 'incomeExpenseType.name',
    },

  ];

  const analytics = ref();
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const type = ref(null);
  const forecastType = ref(null);
  const month = ref(moment(new Date()).format("MM-YYYY"));
  // current selected rows
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: { month: month.value },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchListData = () => {
    isLoading.value = true;
    store
      .dispatch('report/getReportFinanceByMonth', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };
  const fetchAnalytics = () => {
    store.dispatch('report/getAnalyticsReportFinanceByMonth', serverParams.value).then(response => {
      analytics.value = response.data;
    }).catch(error => {
      toastification.showToastError(error);
      isLoading.value = false;
    });
  };

  const fetchData = () => {
    fetchListData();
    fetchAnalytics();
  };

  const exportData = () => {
    store
      .dispatch('report/exportAnalyticsReportFinanceByMonth', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onViewDetailItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(bed, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.bedId = val.id;
    } else {
      delete filter.bedId;
    }
    updateParams({ filter });
  });
  watch(type, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.incomeExpenseTypeId = val.id;
    } else {
      delete filter.incomeExpenseTypeId;
    }
    updateParams({ filter });
  });
  watch(forecastType, val => {
    const { filter } = serverParams.value;
    if (val && val.value !== null) {
      filter.isForecast = val.value;
    } else {
      delete filter.isForecast;
    }
    updateParams({ filter });
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 3;
  };

  return {
    item,
    columns,
    rows,
    analytics,
    apartment,
    room,
    bed,
    type,
    forecastType,
    month,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,

    fetchData,
    fetchListData,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    resolveColWidthIfDisableBed,
    exportData,
  };
}
